import { tradegroupAPI } from "api/tradegroup-api";
import { errorTypes } from "../reducers/types";
import {
  setErrorAC,
  setLoadingAC,
  setRiskSettingsMetadataAC,
  setTGAccountsListAC,
  setTGBalanceAC,
  setTGRequestsListAC,
  setAgencyListAC,
  setTGsListAC,
  updateAccRequestAC,
} from "./actions";
import { notificationHelpers } from "components/notification/helpers";

const {
  tg: {
    SET_TG_ACCOUNTS_LIST_ERROR,
    SET_TG_BALANCE_ERROR,
    SET_TG_REQUESTS_LIST_ERROR,
    GET_RIST_SETTINGS_METADATA_ERROR,
    CREATE_DATA_REQUEST_ERROR,
    UPDATE_REQUEST_STATUS_ERROR,
  },
} = errorTypes;

//Trade Group thunks
export const tradeGroupThunks = {
  getTGsListTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await tradegroupAPI.getTGsList();
      if (response.status === 200) {
        dispatch(setTGsListAC(response.data?.items));
      }
      dispatch(setErrorAC({ type: SET_TG_ACCOUNTS_LIST_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_TG_ACCOUNTS_LIST_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getAgencyListTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await tradegroupAPI.getAgencyList();
      if (response.status === 200) {
        dispatch(setAgencyListAC(response.data?.items));
      }
      dispatch(setErrorAC({ type: SET_TG_ACCOUNTS_LIST_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_TG_ACCOUNTS_LIST_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getTGAccountsListTC: (withParams) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await tradegroupAPI.getAccountsList(withParams);
      if (response.status === 200) {
        dispatch(setTGAccountsListAC(response.data?.items));
      }
      dispatch(setErrorAC({ type: SET_TG_ACCOUNTS_LIST_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_TG_ACCOUNTS_LIST_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  createTradingPackageTC: (body) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await tradegroupAPI.createTradingPackage(body);
      if (response.status === 200) {
        const changeStatusResponse = await tradegroupAPI.setApplicationStatus({
          clientID: body.clientID,
          newstatus: "Received",
        });

        if (changeStatusResponse.status === 200) {
          dispatch(
            tradeGroupThunks.getTGAccountsListTC([
              "openMDrequest",
              "firstEnableRequest",
            ])
          );
        }
      }
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_TG_ACCOUNTS_LIST_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getTGBalanceTC: (id) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await tradegroupAPI.getTGBalance(id);
      if (response.status === 200) {
        dispatch(setTGBalanceAC(response.data?.balance));
      }
      dispatch(setErrorAC({ type: SET_TG_BALANCE_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_TG_BALANCE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getTGRequestsListTC:
    (id, noLoading = false) =>
    async (dispatch) => {
      try {
        !noLoading && dispatch(setLoadingAC(true));
        const response = await tradegroupAPI.getAccountsRequests();
        if (response.status === 200) {
          dispatch(setTGRequestsListAC(response.data?.items));
        }
        dispatch(setErrorAC({ type: SET_TG_REQUESTS_LIST_ERROR, message: "" }));
      } catch (error) {
        dispatch(
          setErrorAC({
            type: SET_TG_REQUESTS_LIST_ERROR,
            message: error.response?.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
  getRiskSettingsMetadataTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await tradegroupAPI.getRSMetadata();
      if (response.status === 200 && response.data.success) {
        dispatch(setRiskSettingsMetadataAC(response.data.items.sort((a, b) => a.order - b.order)));
      }
      dispatch(
        setErrorAC({
          type: GET_RIST_SETTINGS_METADATA_ERROR,
          message: "",
        })
      );
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_RIST_SETTINGS_METADATA_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  createDataRequestTC:
    ({ accID, body, traderID }) =>
    async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const response = await tradegroupAPI.createDataRequest(accID, body);
        if (response.status === 200 && response.data.success) {
          dispatch(tradeGroupThunks.getTGRequestsListTC());
        }
        dispatch(
          setErrorAC({
            type: CREATE_DATA_REQUEST_ERROR,
            message: "",
          })
        );
      } catch (error) {
        notificationHelpers.errorRequest(traderID, dispatch);
        dispatch(
          setErrorAC({
            type: CREATE_DATA_REQUEST_ERROR,
            message: error.response.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
  updateRequestStatusTC:
    ({ requestID, body, action }) =>
    async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const actionResponse = await tradegroupAPI.setRequestStatusAction(
          requestID,
          body,
          action
        );
        if (actionResponse.status === 200 && actionResponse.data.success) {
          dispatch(updateAccRequestAC(actionResponse.data.request));
        }
        dispatch(
          setErrorAC({
            type: UPDATE_REQUEST_STATUS_ERROR,
            message: "",
          })
        );
      } catch (error) {
        dispatch(
          setErrorAC({
            type: UPDATE_REQUEST_STATUS_ERROR,
            message: error.response?.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
  updateRequestTC:
    ({ requestID, body }) =>
    async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const actionResponse = await tradegroupAPI.updateRequest(
          requestID,
          body
        );
        if (actionResponse.status === 200 && actionResponse.data.success) {
          dispatch(updateAccRequestAC(actionResponse.data.request));
        }
        dispatch(
          setErrorAC({
            type: UPDATE_REQUEST_STATUS_ERROR,
            message: "",
          })
        );
      } catch (error) {
        dispatch(
          setErrorAC({
            type: UPDATE_REQUEST_STATUS_ERROR,
            message: error.response?.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
};
