import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { errorTypes } from "reducers/types";
import { constants } from "../../../utilities/constants";
import { authThunks } from "../../../action-thunks/auth-thunks";

import styles from "./styles.module.scss";

const LoginPage = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.utils);

  const [showPassword, setShowPassword] = useState(false);
  const [localState, setLocalState] = useState({
    username: "",
    password: "",
    rememberme: true,
    redirect: false,
  });

  const { username, password, rememberme } = localState;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(constants.paths.TO_GROUP_OVERVIEW);
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "rememberme") {
      setLocalState({ ...localState, [name]: e.target.checked });
    } else setLocalState({ ...localState, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      username,
      password,
      rememberme,
    };

    dispatch(authThunks.loginTC(userData));
  };

  return (
    <div className={styles.mainBlock}>
      <ul className={styles["info-wrapper"]}>
        <li className={styles["info-block"]}>
          <div className={styles.checkmark}>
            <div className={styles.checkmark_stem} />
            <div className={styles.checkmark_kick} />
          </div>
          <span className={cn("zimtra-body-default", styles.info)}>
            $225M+ in Payouts to Zimtra Traders since 2017
          </span>
        </li>
        <li className={styles["info-block"]}>
          <div className={styles.checkmark}>
            <div className={styles.checkmark_stem} />
            <div className={styles.checkmark_kick} />
          </div>
          <span className={cn("zimtra-body-default", styles.info)}>
            5,000+ Traders
          </span>
        </li>
        <li className={styles["info-block"]}>
          <div className={styles.checkmark}>
            <div className={styles.checkmark_stem} />
            <div className={styles.checkmark_kick} />
          </div>
          <span className={cn("zimtra-body-default", styles.info)}>
            100M+ Shares Traded Daily
          </span>
        </li>
        <li className={styles["info-block"]}>
          <div className={styles.checkmark}>
            <div className={styles.checkmark_stem} />
            <div className={styles.checkmark_kick} />
          </div>
          <span className={cn("zimtra-body-default", styles.info)}>
            $2Bn+ Traded Daily
          </span>
        </li>
      </ul>
      <div className={styles["form-block"]}>
        <Form onSubmit={handleSubmit} className="form-wrapper">
          <h3>
            Welcome, <br />
            please sign in
          </h3>
          <Form.Group
            className="form-group-block mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label className="form-label">Username</Form.Label>
            <Form.Control
              value={username}
              onChange={handleChange}
              name="username"
              type="text"
              placeholder="Enter username"
            />
          </Form.Group>

          <Form.Group
            className="form-group-block mb-3"
            controlId="formBasicPassword"
          >
            <Form.Label className="form-label">Password</Form.Label>
            <Form.Control
              value={password}
              onChange={handleChange}
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
            />
            <span
              className="see-pasword"
              onClick={() => setShowPassword(!showPassword)}
            ></span>
          {error && error.type === errorTypes.auth.LOGIN_ERROR && (
            <span className={styles.errorMessage}>{error.message}</span>
          )}
          </Form.Group>
          <Button
            className="main-button"
            size="lg"
            variant="primary"
            type="submit"
            disabled={!username || !password}
          >
            Sign in
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
