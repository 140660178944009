import { instance, setHeader } from "./auth-api";

const accounturl = "/agency";

export const statementsAPI = {
  getStatements: async (agencyID) => {
    const response = await instance.get(
      `${accounturl}/${agencyID}/statement`,
      {
        headers: setHeader(),
      }
    );
    return response;
  },
  getStatementFile: async (agencyID, fileID) => {
    const response = await instance.get(
      `${accounturl}/${agencyID}/statement/${fileID}`,
      {
        headers: setHeader(),
        responseType: "blob", // important
      }
    );
    return response;
  },
};
